<template>
  <div class="dataTables_wrapper dt-bootstrap4 no-footer">
    <div class="table-responsive">
      <table :id="id"
          class="table
          table-sm
          align-middle
          table-row-dashed
          fs-6
          dataTable
          no-footer w-100 table-hover">
        <thead>
        <tr class="text-start fs-7 text-gray-400 text-uppercase gs-0">
          <th v-for="(column, index) in columns" :key="index"
              :class="column.class ? column.class : 'min-w-80px text-center' " v-html="getBody(index, column)">
          </th>
        </tr>
        </thead>
        <tbody class="fw-bold text-gray-600">
        </tbody>
      </table>
    </div>
  </div>
</template>


<script>
import 'jquery/dist/jquery.min.js';
import 'datatables.net-responsive'

import JwtService     from '@/core/services/JwtService';
import $              from 'jquery';
import {
  watch,
  defineComponent,
  nextTick,
  ref
}                     from 'vue';
import { l }          from '@/core/helpers/lang';
import useSearchInput from '@/components/backoffice/composables/search.js';
import useDateInput   from '@/components/backoffice/composables/date';

export default defineComponent ( {
  name   : 'TableBaseComponent',
  props  : {
    id          : {
      type: String
    },
    url         : {
      type: String
    },
    columns     : {
      type: Object
    },
    columnDefs  : {
      type: Object
    },
    drawCallback: {
      type   : Function,
      default: () => void 0
    },
  },
  methods: {
    getBody ( index, column )
    {
      return this.columns[ index ].body
          ? this.columns[ index ].body ( column.title )
          : this.columns[ index ].title;

    }
  },
  data   : () =>
  {
    return {
      table: null
    };
  },

  mounted ()
  {
    const { search } = useSearchInput ();
    const { date }   = useDateInput ();
    let startDate    = ref ( '' );
    let endDate      = ref ( '' );


    let dataColumns = JSON.parse ( JSON.stringify ( this.columns ) );
    dataColumns     = dataColumns.map ( element => element.settings );

    this.table = $ ( '#' + this.id ).DataTable ( {
      ordering  : true,
      processing: true,
      serverSide: true,
      ajax      : {
        url       : this.url,
        type      : 'GET',
        data      : ( d ) =>
        {
          d.startDate = startDate.value;
          d.endDate   = endDate.value;
          return d;
        },
        beforeSend: function ( request )
        {
          request.setRequestHeader ( 'Authorization', 'Bearer ' + JwtService.getToken () );
        }
      },
      dom         : '<\'table-responsive\'tr><\'row\'<\'col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start mb-2\'l><\'col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end\'p>>',
      colReorder  : true,
      pageLength  : 50,
      responsive  : true,
      columns     : dataColumns,
      columnDefs  : this.columnDefs,
      pagingType  : 'simple_numbers',
      lengthMenu  : [10,25,50,100,250,500,1000],
      language    : {
        'lengthMenu'     : '_MENU_',
        'zeroRecords'    : l ( 'admin:languages:languages464' ),
        search           : '_INPUT_',
        searchPlaceholder: l ( 'admin:languages:languages465' ),
        processing: '<div class="lds-ripple"><div></div><div></div></div>',
        "paginate": {
          "previous": '<div class="page-link"><i class="previous"></i></div>',
          "next": '<div class="page-link"><i class="next"></i></div>'
        }

      },
      'order'   : [
        [
          1,
          'desc'
        ]
      ],
      drawCallback: () => this.drawCallback ( this )
    } );

    watch ( search, ( newQuery, oldQuery ) =>
    {
      this.table.search ( newQuery ).draw ();
    } );

    watch ( date, ( newQuery, oldQuery ) =>
    {
      startDate.value = newQuery.start;
      endDate.value   = newQuery.end;
      this.table.ajax.reload ();
    } );
  },
} );
</script>
<style>
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid rgb(36, 197, 197);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
</style>
