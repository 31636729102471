import toastr from 'toastr/build/toastr.min';
import 'toastr/build/toastr.min.css';

export default function toast ( mesaj, tip? )
{
	if(typeof tip ==='undefined')
		tip = true
	return new Promise ( function ( resolve, reject )
	{
		if ( tip === 'error' || tip === 'success' || tip === 'warning' || tip === true || tip === false || tip === 1 || tip === 0 )
		{
			toastr.options = {
				"closeButton"       : false,
				"debug"             : false,
				"newestOnTop"       : false,
				"progressBar"       : true,
				"positionClass"     : "toast-bottom-right",
				"preventDuplicates" : false,
				"onclick"           : null,
				"showDuration"      : "300",
				"hideDuration"      : "1000",
				"timeOut"           : "5000",
				"extendedTimeOut"   : "1000",
				"showEasing"        : "swing",
				"hideEasing"        : "linear",
				"showMethod"        : "fadeIn",
				"hideMethod"        : "fadeOut",
			};
			switch ( tip )
			{
				case 'error':
				case 'success':
				case 'warning':
					toastr[ tip ] ( mesaj );
					break;
				case 1:
				case true:
					toastr.success ( mesaj );
					break;
				case 0:
				case false:
					toastr.error ( mesaj );
					break;
			}
			
			resolve ( true );
		}
		else
		{
			reject ( false );
		}
	} );
};