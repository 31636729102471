<template>
  <div class="card">
    <div class="card-body">
      <TableBaseComponent v-if="parameters" id="customDatatable" :url="url" :columnDefs="columnDefs" :columns="columns(parameters)" :drawCallback="drawCallback"/>
    </div>
  </div>
  <div class="modal fade" id="treeModal" tabindex="-1" aria-modal="true" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">{{ l ( 'admin:genel:bayi_yerlestir' ) }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" id="bt">
          <table class="table table-bordered table-hover">
            <thead style="background-color: rgba(0,0,0,0.04);">
            <tr>
              <th width="50px">ID</th>
              <th>{{ l ( 'admin:genel:adi_ve_soyadi' ) }}</th>
              <th width="50px">{{ l ( 'admin:genel:Sol Kol' ) }}</th>
              <th width="50px">{{ l ( 'admin:genel:Sağ Kol' ) }}</th>
            </tr>
            </thead>
            <tbody>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
          <button type="button" class="btn btn-primary">{{ l ( 'admin:genel:yerlestir' ) }}</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import 'datatables.net-dt/js/dataTables.dataTables';
import TableBaseComponent      from '@/components/backoffice/table/TableBaseComponent.vue';
import {
  computed,
  onMounted,
  ref
}                              from 'vue';
import { setCurrentPageTitle } from '@/core/helpers/breadcrumb';
import { l }                   from '@/core/helpers/lang';
import store                   from '@/store';
import post                    from '@/core/functions/post';
import get                     from '@/core/functions/get';
import $                       from 'jquery';
import 'bootstrap/dist/js/bootstrap.js';
import { api_url }             from '../../../ayar';
import toast                   from '../../../core/functions/toast';
import router                  from '../../../router';

const columns = ( parameters ) =>
{
  return [
    {
      title   : `<div class="form-check form-check-sm form-check-custom form-check-solid me-3 ms-2">
                            <input class="form-check-input" type="checkbox" data-kt-check="true" data-kt-check-target="#kt_customers_table .form-check-input" value="1" />
                        </div>`,
      class   : 'w-10px pe-2 ',
      settings: {
        data      : 'id',
        orderable : false,
        searchable: false
      }
    },
    {
      title   : l ( 'user:languages:languages233' ),
      settings: {
        data     : 'name',
        orderable: true
      }
    },
    {
      title   : l ( 'admin:transfer:E-posta' ),
      settings: {
        data     : 'email',
        orderable: true
      }
    },
    {
      title   : l ( 'admin:transfer:GSM' ),
      settings: {
        data     : 'phone',
        orderable: true
      }
    },
    {
      title   : l ( 'admin:languages:languages437' ),
      settings: {
        data     : 'city',
        orderable: true
      }
    },
    {
      title   : l ( 'admin:transfer:Kayıt Tarihi' ),
      settings: {
        data     : 'created_at',
        orderable: true
      }
    },
    {
      title   : l ( 'admin:transfer:Detay' ),
      settings: {
        data     : 'id',
        orderable: false,
        visible  : parameters.backoffice_agac_yerlestirme
      }
    },
  ];
};


export default ( {
  name      : 'YerleşimBekleyenBayiler',
  components: {
    TableBaseComponent
  },
  data      : () =>
  {
    return {
      url       : api_url + 'waitingRoom',
      columnDefs: [
        {
          'render' : function ( data, type, row )
          {
            return `<div class="form-check form-check-sm form-check-custom form-check-solid ms-2">
                                <input
                                class="form-check-input widget-13-check"
                                type="checkbox"
                                value="${ row[ 'id' ] }"/>
                            </div>`;
          },
          'targets': 0,
        },
        {
          'render' : function ( data, type, row )
          {
            return '<div class=\'text-muted fw-bold text-muted d-block fs-7\' >' + data + '</div>';
          },
          'targets': 1,
          className: 'text-left align-bottom'
        },
        {
          'render' : function ( data, type, row )
          {
            return '<div class=\'text-muted fw-bold text-muted d-block fs-7\' >' + data + '</div>';

          },
          'targets': 2,
          className: 'text-left align-bottom'
        },
        {
          'render' : function ( data, type, row )
          {
            return '<div class=\'text-muted fw-bold text-muted d-block fs-7\' >' + data + '</div>';
          },
          'targets': 3,
          className: 'text-right align-bottom'
        },
        {
          'render' : function ( data, type, row )
          {
            return '<div class=\'text-muted fw-bold text-muted d-block fs-7\' >' + data + '</div>';

          },
          'targets': 4,
          className: 'text-left align-bottom'
        },
        {
          'render' : function ( data, type, row )
          {
            return '<div class=\'text-muted fw-bold text-muted d-block fs-7\' >' + data + '</div>';
          },
          'targets': 5,
          className: 'text-right align-bottom'
        },
        {
          'render' : function ( data, type, row )
          {
            return `<button class="btn btn-icon btn-bg-light addModalYerlestir btn-active-color-warning btn-sm me-1" data-sponsor-id="${ row.sponsor_id }" data-bs-toggle="modal"
                  data-bs-target="#treeModal"><i class="fas fa-plus"></i></button>`;
          },
          'targets': 6
        }
      ],
    };
  },
  methods   : {
    drawCallback: function ( obj )
    {
      document.querySelectorAll ( '.addModalYerlestir' ).forEach ( addModalYerlestir =>
      {
        addModalYerlestir.addEventListener ( 'click', ( e ) =>
        {
          let id = e.currentTarget.dataset.sponsorId;
          post ( api_url + 'bayi/bt_last_user' ).then ( req =>
          {
            req = req.data;
            if ( req.durum === true )
            {
              const leftUser  = req.mesaj.left;
              const rightUser = req.mesaj.right;
              $ ( '#bt .append' ).each ( function ()
              {
                $ ( this ).remove ();
              } );
              let html = '';
              if ( leftUser !== null )
              {
                html += `<tr class='append'>
                <td>${ leftUser.sponsor_id }</td>
                <td>${ leftUser.name }</td>
                <td>

                  <a href="Javascript:window.addToTreeMain(${ id },'left',${ leftUser.sponsor_id },'user')" class="btn btn-accent sagKol"><i class="fa fa-chevron-left"></i></a>

                </td>
                <td>
                </td>
              <tr>`;
              }
              if ( rightUser !== null )
              {
                html += `<tr class='append'>
                <td>${ rightUser.sponsor_id }</td>
                <td>${ rightUser.name }</td>
                <td>
                </td>
                <td>

                  <a href="Javascript:window.addToTreeMain(${ id },'right',${ rightUser.sponsor_id },'user')" class="btn btn-accent sagKol"><i class="fa fa-chevron-right"></i></a>

                </td>
              </tr>`;
              }
              if ( leftUser == null && rightUser == null )
              {
                html += `
									<div class="alert alert-danger w-100" role="alert">
										Kişi Ağaca Yerleştirilemez!
									</div>
							`;
                $ ( '#bt' ).html ( '' ).append ( html );
              }
              $ ( '#bt table' ).append ( html );
              if($('#bt table tbody tr').length===0){
                $("#bt").html('').append(`<div class="mt-5 text-center">
                  <img src="../media/illustrations/sketchy-1/5.png" class="img-fluid w-50">
                  <div class=" text-gray-400 fs-2 p-4 fw-bold">${l('web:languages:languages469')}</div>
                </div>`)
              }
            }
          } );
        } );
      } );
    },
  },
  setup ()
  {
    onMounted ( () =>
    {
      setCurrentPageTitle ( l ( 'admin:transfer:Yerleşim Bekleyen Bayiler' ) );
    } );
    return {
      l,
      yerlestirModal: ref ( false ),
      columns,
      parameters    : computed ( () => store.getters.getParameterList )
    };
  },
  mounted ()
  {
    window.addToTreeMain = function ( sponsorId, side, PassYerlestirmeId )
    {
      get ( `${ api_url }bayi/add_to_tree/${ PassYerlestirmeId }/${ sponsorId }/${ side }` )
          .then ( res =>
          {
            res = res.data;
            if ( res.durum === true )
            {
              toast ( l ( 'admin:genel:agaca_eklendi' ), true );
              router.push ( { path: '/network_tree' } );
            }
          } );
    };
  }
} );
</script>


<style scoped>

</style>